import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';

window.addEventListener( 'DOMContentLoaded', () => {
    console.log( 'DOM fully loaded and parsed' );
    const element = document.querySelector(
        '.wp-block-as-blocks-slider'
    );
    if ( element ) {
        let attributes = { ...element.dataset};
        Object.keys( attributes ).forEach( key => {
            if ( attributes[key] === 'true' ) {
                attributes[key] = true;
            }
            if ( attributes[key] === 'false' ) {
                attributes[key] = false;
            }
        } );

        let swiperOptions = {
            slideClass: 'wp-block-as-blocks-slide',
            slidesPerView: attributes.numberSlides,
            centeredSlides: true,
            spaceBetween: 30,
            modules: [Pagination, Navigation],
        }
        if ( attributes.autoplay ) {
            swiperOptions.autoplay = {
                delay: attributes.autoplaySpeed * 1000,
            }
            swiperOptions.modules.push( Autoplay );
        }
        if (attributes.pagination) {
            swiperOptions.pagination = {
                el: '.swiper-pagination',
                type: 'bullets',
            }
        }
        if(attributes.navigation) {
            swiperOptions.navigation = {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        }
        console.log(swiperOptions)
        const swiper = new Swiper('.wp-block-as-blocks-slider', {...swiperOptions});
    }
} );
